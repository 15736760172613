import { RumInitConfiguration } from '@datadog/browser-rum-slim';
import { addDays } from 'date-fns';
import { ToastPosition } from 'react-toastify';
import { CookieSetOptions } from 'universal-cookie';

import { ISiteSubNavLinkItem } from '../components/Navigation/SiteSubNavigation/helpers/siteSubNavLinks';
import { getMykissEnv } from '../helpers/environment/getMykissEnv';
import { isBrowser } from '../helpers/environment/isBrowser';
import { isProduction } from '../helpers/environment/isProduction';

import { SERVER_ENV_VARS, UNIVERSAL_ENV_VARS } from './environmentVariables';

export const MILLION_USERS = '15';
export const MILLION_WATERS = '1.7';
export const MILLION_CATCHES = '14';
export const THOUSAND_GEAR_REVIEWS = '300';
export const MILLION_PERSONAL_BESTS = '1';

/* Based off of the blue header. Update when we have the white header */
export const DEFAULT_HEADER_HEIGHT = 180;

export const PAGE_TITLE_SUFFIX = ' | Fishbrain';
export const DEFAULT_LOGGED_IN_ROUTE = '/';
export const MIN_PASSWORD_LENGTH = 8;

export const MAX_COOKIE_LENGTH_IN_DAYS = 180;
export const MAX_COOKIE_LENGTH_IN_SECONDS = MAX_COOKIE_LENGTH_IN_DAYS * 24 * 60 * 60;
export const DEFAULT_COOKIE_OPTIONS: CookieSetOptions = {
  path: '/',
  expires: addDays(new Date(), MAX_COOKIE_LENGTH_IN_DAYS),
};
export const COOKIE_EXPIRES_SECONDS = 86400;
export const COUPON_COOKIE_OPTIONS = {
  ...DEFAULT_COOKIE_OPTIONS,
  maxAge: COOKIE_EXPIRES_SECONDS,
};
export const CLOUDFRONT_HEADERS_COOKIE_KEY = 'fib-cf-headers';
export const SERVER_REQUEST_HEADERS = { 'USER-AGENT': 'mykiss-web' };
export const FISH_RULES_HEADERS = {
  'x-client-id': SERVER_ENV_VARS.FISHRULES_CLIENT_ID,
  'x-api-key': SERVER_ENV_VARS.FISHRULES_API_KEY,
  ...(!isBrowser() && SERVER_REQUEST_HEADERS),
};
export const INCORRECT_EMAIL_TOKEN_MSG = 'Incorrect email verification token.';
export const RECAPTCHA_SITE_KEY = UNIVERSAL_ENV_VARS.RECAPTCHA_KEY;
export const APP_DEEPLINK = isProduction()
  ? 'https://link.fishbrain.com/main'
  : 'https://link-staging.fishbrain.com/main';

export const ACCEPTED_IMAGE_UPLOAD_TYPES = ['image/jpeg', 'image/png'];
export const ACCEPTED_VIDEO_UPLOAD_TYPES = ['video/mp4', '.mov', 'video/webm'];
export const BLOG_POSTS_PER_PAGE = 12;
export const BLOG_POSTS_PREBUILD_PAGES = 3;
export const CONTENTFUL_REQUEST_ENTRIES_LIMIT = 1000;

// Consent Agreement UUIDs
export const CURRENT_CONSENT_MARKETING_EMAIL_UUID = 'fishbrain-marketing-email-rev_1_0-en';
export const CURRENT_CONSENT_TERMS_UUID = 'fishbrain-terms-and-conditions-rev_2_0-en';
export const CURRENT_CONSENT_PRIVACY_UUID = 'fishbrain-privacy-policy-rev_2_0-en';

interface ToastConfig {
  position: ToastPosition;
  autoClose: number;
}

export const TOAST_CONFIG: ToastConfig = {
  position: 'bottom-center',
  autoClose: 8000,
};

export const CANONICAL_ROOT_URL = 'https://fishbrain.com';
export const META_HOMEPAGE_TITLE = 'Fishing App and Fishing Tools';
export const META_EXPLORE_TITLE = 'Explore your fishing area';

export const CATCH_MAP_CTA_TO_PRO_TITLE =
  'With Fishbrain Pro you can see who is catching what where!';
export const CATCH_MAP_CTA_TO_PRO_BODY = `Over ${MILLION_USERS} million anglers trust Fishbrain. See when and where to target your preferred species.`;

export const DATADOG_RUM_CONFIG: RumInitConfiguration = {
  applicationId: 'b080e31a-eda5-41eb-86e9-c67f024fd393',
  clientToken: UNIVERSAL_ENV_VARS.CLIENT_DATADOG_TOKEN,
  env: getMykissEnv(),
  service: 'mykiss',
  trackViewsManually: true,
  allowedTracingUrls: [/https:\/\/.*\.fishbrain\.com/],
  sessionSampleRate: 10,
};

export const brandNotificationsChannel = isProduction()
  ? '#brand-applications'
  : '#mykiss-test-channel';
export const suggestedChangesChannel = isProduction()
  ? '#user-suggested-changes'
  : '#mykiss-test-channel';

export const CHECKOUT_NOT_SUCCESSFULL = 'failure';
export const CHECKOUT_SUCCESSFULL = 'success';
export const CHECKOUT_PENDING = 'pending';

export const ACCEPTED_VIDEO_MIME_TYPES = ['video/mp4', 'video/webm'];

export const META_FEATURES_TITLE = 'Fishing App Features';
export const META_FEATURES_DESCRIPTION =
  'Learn about how Fishbrain can make you a better angler. Discover the features available, and download the app to try today.';

export const META_FISHING_SPOT_TITLE = 'Find fishing spots near you';
export const META_FISHING_SPOT_DESCRIPTION =
  'Find the best local fishing spots with our local fishing spot map and report. See nearby locations, species, fishing forecasts and more.  Sign up for free.';

export const META_FISHING_DEPTH_MAPS_TITLE = 'Fishing depth and contour maps';
export const META_FISHING_DEPTH_MAPS_DESCRIPTION =
  'Access depth maps and contour maps that will improve your fishing. Visualize the depth of local fishing spots and plan your next trip with Fishbrain.';

export const META_FISHING_LOG_BOOK_TITLE = 'Fishing logbook and journal';
export const META_FISHING_LOG_BOOK_DESCRIPTION =
  'Log your catches and keep accurate notes such as size, weight and location. Fishbrain is a fishing journal in the palm of your hand. Join now for free.';

export const META_FISHING_WAYPOINTS_TITLE = 'Plan your next fishing trip with waypoints';
export const META_FISHING_WAYPOINTS_DESCRIPTION =
  'With the Fishbrain app you can easily add notes as "waypoints" to remember important information that will help you plan for your next fishing trip.';

export const META_FISHING_FISHING_FORECAST_TITLE = 'Fishing forecasts: the best times to fish';
export const META_FISHING_FISHING_FORECAST_DESCRIPTION =
  "See the best times to fish near you with Fishbrain's fishing forecasts. See weather, tide, lunar charts and even a prediction of when fish are most active.";

export const META_RULES_AND_REGULATIONS_TITLE = 'Rules and Regulations: fish with confidence';
export const META_RULES_AND_REGULATIONS_DESCRIPTION =
  'Quikly find where in the fishing season you are and the bag limit for your catch.';

export const USER_AVATAR_WIDTH = 128;
export const USER_AVATAR_HEIGHT = 128;

export const CATCH_MAP_WIDTH = 650;
export const CATCH_MAP_HEIGHT = 450;

export const DEFAULT_ZOOM_LEVEL = 12;

export const NO_REGULATION_TITLE = 'No regulations for this area yet';
export const NO_REGULATION_DESCRIIPTION =
  'We are working on adding regulations to your area. Please contact your regulation provider and ask them to support Fishbrain.';
export const REGULATION_DISCLAIMER_TEXT =
  'Disclaimer: Always check local fishing regulations, water access rights and land ownership before fishing, regardless of any catches logged in that area by the Fishbrain community. Fishbrain has mapped millions of acres of government-owned land across the USA to help you identify potential fishing access, but you are responsible for ensuring compliance with all legal requirements.';

export const SIGNUP_METHODS = {
  facebook: 'facebook',
  google: 'google',
  apple: 'apple',
  manual: 'manual',
} as const;

export const DEFAULT_LOCAL_LANGUAGE = 'en-US';

export const FEATURE_PAGE_NAV_LINKS: ISiteSubNavLinkItem[] = [
  {
    id: 'features',
    title: 'Fish smarter',
    path: '/features',
  },
  {
    id: 'regulations',
    title: 'Rules and Regulations',
    path: '/features/rules-and-regulations',
  },
  {
    id: 'fishingSpots',
    title: 'Fishing Spots',
    path: '/features/fishing-spots',
  },
  {
    id: 'fishingForcast',
    title: 'Fishing Forecast',
    path: '/features/fishing-forecasts',
  },
  {
    id: 'depthMaps',
    title: 'Depth Maps',
    path: '/features/depth-maps',
  },
  {
    id: 'logACatch',
    title: 'Log your catches',
    path: '/log-a-catch',
  },
  {
    id: 'waypoints',
    title: 'Waypoints',
    path: '/features/waypoints',
  },
  {
    id: 'logbook',
    title: 'Logbook',
    path: '/features/logbook',
  },
];

export const DISCOVER_PAGES_NAV_LINKS: ISiteSubNavLinkItem[] = [
  {
    id: 'discover',
    title: 'Explore Fishing',
    path: '/discover',
  },
  {
    id: 'discoverSpecies',
    title: 'Explore Species',
    path: '/discover/species',
  },
  {
    id: 'discoverUS',
    title: 'Fishing in the US',
    path: '/countries/us-united-states',
    subPages: ['/countries/us-united-states/', '/cities/us'],
  },
  {
    id: 'popularWaters',
    title: 'Popular waters',
    path: '/fishing-waters/popular',
  },
];

export const BLOG_PAGE_NAV_LINKS: ISiteSubNavLinkItem[] = [
  {
    id: 'all',
    title: 'All posts',
    path: '/blog',
  },
  {
    id: 'species',
    title: 'Species',
    path: '/blog/species',
  },
  {
    id: 'category',
    title: 'Categories',
    path: '/blog/categories',
  },
  {
    id: 'contributors',
    title: 'Contributors',
    path: '/blog/authors',
  },
];

export const IOS_APP_RATING = 4.7;
export const IOS_ALL_RATING_COUNT = '37.6K';
export const AVERAGE_APP_RATING = 4.3;
export const ANDROID_RATING_COUNT = '93K+';

export const CANONICAL_ADVERTISE_URL = 'https://fishbrain.com';
export const META_ADVERTISE_TITLE = 'Meet Fishbrain';
export const ADVERTISE_EMAIL = 'advertise@fishbrain.com';
export const ADVERTISE_CONTACTS = [
  {
    contactName: 'Jack McKinney',
    contactPosition: 'Director of Sales and Business Development',
    contactEmail: 'jack.mckinney@fishbrain.com',
    contactPhone: '+1(314) 703-2786',
  },
  {
    contactName: 'Ben Parsons',
    contactPosition: 'Key Accounts Manager',
    contactEmail: 'ben.parsons@fishbrain.com',
    contactPhone: '+1(208) 615-1536',
  },
];

export const WEB_GL_DISABLED_WARNING_MESSAGE =
  'WebGL must be enabled in your browser in order to view the map.';
export const WEB_GL_DISABLED_ERROR_MESSAGE = 'Failed to initialize WebGL';
